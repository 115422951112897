import React from "react"
import Layout from "../components/Layout"
import TermsComponent from "../components/Privacy/Terms"
import { graphql, useStaticQuery } from "gatsby"
import HelmetComponent from "../components/Helmet"
import useIsClient from "../hooks/rehydration"
// import Loader from "../components/Loader/Loader"
function Terms() {
  const { isClient } = useIsClient()

  const data = useStaticQuery(graphql`
    {
      allContentfulSeoEach(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            id
            title
            url
            description
            facebookImage {
              fixed {
                srcWebp
              }
            }
          }
        }
      }
      allContentfulPrivacyTerms(
        limit: 1
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            mainTitle
            mainPara {
              raw
            }
          }
        }
      }
    }
  `)
  let seo = data.allContentfulSeoEach.edges[5].node
  if (!isClient) return <HelmetComponent seo={seo} />
  return (
    <Layout>
      <HelmetComponent seo={seo} />
      <TermsComponent data={data} />
    </Layout>
  )
}

export default Terms
